var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { difference as _difference } from 'lodash';
import React, { memo } from 'react';
import { FormGroup } from 'reactstrap';
import { Input } from 'reactstrap';
import { Label } from 'reactstrap';
import { AXIS_NAMES, AXIS_Z, READING_TYPES } from '../../../constants';
import { If } from '../../../shared/components/If';
var AxisType = function (_a) {
    var selectedReadingTypeIds = _a.selectedReadingTypeIds, setSelectedReadingTypeIds = _a.setSelectedReadingTypeIds, chartIdentifiers = _a.chartIdentifiers, isSupportZAxis = _a.isSupportZAxis, _b = _a.showAll, showAll = _b === void 0 ? true : _b, _c = _a.isDisabled, isDisabled = _c === void 0 ? false : _c;
    var selectAll = function () {
        if (selectedReadingTypeIds.length < 3) {
            setSelectedReadingTypeIds(chartIdentifiers);
        }
    };
    var handleToggleAxis = function (chartIdentifier, value) {
        if (value) {
            setSelectedReadingTypeIds(__spreadArray(__spreadArray([], selectedReadingTypeIds, true), [chartIdentifier], false));
        }
        else {
            if (selectedReadingTypeIds.length === 1 && selectedReadingTypeIds.includes(chartIdentifier))
                return;
            setSelectedReadingTypeIds(_difference(selectedReadingTypeIds, [chartIdentifier]));
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx("span", { className: "fw-bold mt-2 d-block align-middle", children: "Axis Type:" }), _jsx(If, { condition: showAll, children: _jsxs(FormGroup, { check: true, inline: true, children: [_jsx(Input, { type: "checkbox", checked: selectedReadingTypeIds.length === 3, onChange: selectAll, disabled: isDisabled }), _jsx(Label, { check: true, children: "All" })] }) }), chartIdentifiers.map(function (id) {
                var _a;
                var axisShort = (_a = READING_TYPES[id]) === null || _a === void 0 ? void 0 : _a.axisShort;
                if (!isSupportZAxis && axisShort === AXIS_NAMES[AXIS_Z]) {
                    return null;
                }
                return (_jsxs(FormGroup, { check: true, inline: true, children: [_jsx(Input, { checked: selectedReadingTypeIds.includes(+id), type: "checkbox", disabled: isDisabled, onChange: function (e) { return handleToggleAxis(id, e.target.checked); } }), _jsx(Label, { check: true, children: axisShort })] }, id));
            })] }));
};
export default memo(AxisType);
