// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alert-level-block {
  padding: 10px;
  background: #f1f1f1;
  border: 1px solid #c1c1c1;
  border-radius: 4px;
}
.alert-level-block:focus-within {
  background: rgba(227, 187, 49, 0.1);
  border: 1px solid #e3bb31;
}

.dark-layout .alert-level-block {
  background: #2e2c28;
}
.dark-layout .alert-level-block.selected {
  background: rgba(227, 187, 49, 0.1);
}

.alert-levels {
  display: grid;
  gap: 20px;
}
.alert-levels__section {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 10px;
  border: 1px solid #c1c1c1;
  border-radius: 4px;
}
.alert-levels__section .btn-delete-alert-level {
  display: flex;
  gap: 5px;
  align-items: center;
  align-self: end;
}`, "",{"version":3,"sources":["webpack://./../src/modals/changeAlarmLevels/components/tabs/alertLevelsTab/AlertLevelsTab.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,kBAAA;AACF;AACE;EACE,mCAAA;EACA,yBAAA;AACJ;;AAIE;EACE,mBAAA;AADJ;AAGI;EACE,mCAAA;AADN;;AAMA;EACE,aAAA;EACA,SAAA;AAHF;AAKE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,kBAAA;EACA,yBAAA;EACA,kBAAA;AAHJ;AAKI;EACE,aAAA;EACA,QAAA;EACA,mBAAA;EACA,eAAA;AAHN","sourcesContent":[".alert-level-block {\n  padding: 10px;\n  background: #f1f1f1;\n  border: 1px solid #c1c1c1;\n  border-radius: 4px;\n\n  &:focus-within {\n    background: rgba(227, 187, 49, 0.1);\n    border: 1px solid #e3bb31;\n  }\n}\n\n.dark-layout {\n  .alert-level-block {\n    background: #2e2c28;\n\n    &.selected {\n      background: rgba(227, 187, 49, 0.1);\n    }\n  }\n}\n\n.alert-levels {\n  display: grid;\n  gap: 20px;\n\n  &__section {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    padding: 15px 10px;\n    border: 1px solid #c1c1c1;\n    border-radius: 4px;\n\n    .btn-delete-alert-level {\n      display: flex;\n      gap: 5px;\n      align-items: center;\n      align-self: end;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
