var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { get as _get, set as _set, size as _size } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { FaCheckCircle, FaExclamation, FaInfo, FaTimes, FaTrashAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Badge, Col, Input, Label, Row } from 'reactstrap';
import { Button } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import Endpoints from '../../../../../api/endpoints/endpoints';
import { READING_TYPES, READING_TYPE_TACHOMETER, SYSTEM_TYPE_REMASTERED } from '../../../../../constants';
import { If } from '../../../../../shared/components/If';
import { SystemTypeContext } from '../../../../../shared/context/SystemTypeContext';
import { ChartFeaturesStore } from '../../../../../shared/store/charts/chartsContent/ChartFeaturesStore';
import { InstallationPointStore } from '../../../../../shared/store/charts/chartsContent/InstallationPointStore';
import { useConfirmModalActions } from '../../../../../shared/store/global/useConfirmModalStore';
import { LossPacketsAlert } from '../../../../../widgets/common';
import Chart from '../../Chart';
import './AlertLevelsTab.scss';
import AlertLevelBlock from './components/AlertLevelBlock';
var DEFAULT_ALERT_CONDITION = {
    conditions: { caution: '>=', warning: '>=' },
    levels: { caution: '', warning: '' },
    settings: { caution: null, warning: null },
    fftSettings: {
        caution: {
            fmax: 600,
            limit: 5,
            lor: 1024,
        },
        warning: {
            fmax: 600,
            limit: 5,
            lor: 1024,
        },
    },
    isRmsOd: { caution: 0, warning: 0 },
    id: null,
    ids: null,
};
export var AlertLevelsTab = function (_a) {
    var setSmallLoader = _a.setSmallLoader, selectedInstallationPointId = _a.installationPointId, selectedReadingTypeIds = _a.selectedReadingTypeIds, onSelectReadingType = _a.onSelectReadingType, chartIdentifiers = _a.chartIdentifiers;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _b = ChartFeaturesStore(function (state) { return ({
        changeAlertConditions: state.changeAlertConditions,
        setChartFeatures: state.setStates,
    }); }, shallow), changeAlertConditions = _b.changeAlertConditions, setChartFeatures = _b.setChartFeatures;
    var readingTypeId = changeAlertConditions.readingTypeId;
    var _c = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
        overlayInstallationPoints: state.overlayInstallationPoints,
        packetLossPercent: state.packetLossPercent,
    }); }, shallow), installationPoint = _c.installationPoint, overlayInstallationPoints = _c.overlayInstallationPoints, packetLossPercent = _c.packetLossPercent;
    var _d = useState(false), forAllSensors = _d[0], setForAllSensors = _d[1];
    var _e = useState(false), forOverlaid = _e[0], setForOverlaid = _e[1];
    var _f = useState({}), alertConditions = _f[0], setAlertConditions = _f[1];
    var _g = useState(false), areAlertConditionsFilled = _g[0], setAlertConditionsFilled = _g[1];
    // const measure = useMeasure();
    useEffect(function () {
        if (!areAlertConditionsFilled) {
            if (_size(changeAlertConditions.conditions)) {
                setAlertConditions(Object.keys(changeAlertConditions.conditions).reduce(function (acc, key) {
                    acc[key] = {
                        conditions: changeAlertConditions.conditions[key],
                        fftSettings: changeAlertConditions.fftSettings[key],
                        isRmsOd: Object.entries(changeAlertConditions.fftSettings[key]).reduce(function (acc, _a) {
                            var level = _a[0], fftSettings = _a[1];
                            acc[level] = Object.values(fftSettings).every(Boolean);
                            return acc;
                        }, {
                            caution: 0,
                            warning: 0,
                        }),
                        levels: changeAlertConditions.storedLevels[key],
                        settings: changeAlertConditions.settings[key],
                        id: key,
                        ids: changeAlertConditions.ids[key],
                    };
                    if (changeAlertConditions.currentLevel.id === key) {
                        var _a = changeAlertConditions.currentLevel, caution = _a.caution, warning = _a.warning;
                        acc[key].levels = {
                            caution: Number(caution).toFixed(2) || null,
                            warning: Number(warning).toFixed(2) || null,
                        };
                    }
                    return acc;
                }, {}));
            }
            else {
                var _a = changeAlertConditions.currentLevel, caution = _a.caution, warning = _a.warning;
                setAlertConditions({
                    '1': __assign(__assign({}, structuredClone(DEFAULT_ALERT_CONDITION)), { levels: {
                            caution: Number(caution) || null,
                            warning: Number(warning) || null,
                        } }),
                });
            }
            setAlertConditionsFilled(true);
        }
    }, [changeAlertConditions, areAlertConditionsFilled]);
    var hasAutoAi = Object.values(alertConditions).some(function (_a) {
        var _b, _c;
        var settings = _a.settings;
        return ((_b = settings.caution) === null || _b === void 0 ? void 0 : _b.is_ai) || ((_c = settings.warning) === null || _c === void 0 ? void 0 : _c.is_ai);
    });
    var triggerEachReading = Object.values(alertConditions).some(function (_a) {
        var _b, _c;
        var settings = _a.settings;
        return Number((_b = settings.caution) === null || _b === void 0 ? void 0 : _b.trigger_time) > 0 || Number((_c = settings.warning) === null || _c === void 0 ? void 0 : _c.trigger_time) > 0;
    });
    var _h = useState([]), comments = _h[0], setComments = _h[1];
    useEffect(function () {
        setSmallLoader(true);
        if (readingTypeId) {
            Endpoints[selectedSystemType]
                .getAlertConditionComments({
                query: {
                    installationPointIds: [installationPoint.id],
                    readingTypeIds: [readingTypeId],
                },
            })
                .then(function (response) {
                if (response === null || response === void 0 ? void 0 : response.data) {
                    setComments(response.data);
                }
            })
                .finally(function () {
                setSmallLoader(false);
            });
        }
    }, [installationPoint.id, readingTypeId]);
    var _j = useState({
        caution: null,
        warning: null,
    }), customerLevels = _j[0], setCustomerLevels = _j[1];
    var _k = useState(false), isVisibleCustomerLevels = _k[0], setIsVisibleCustomerLevels = _k[1];
    useEffect(function () {
        if (selectedSystemType === SYSTEM_TYPE_REMASTERED && readingTypeId) {
            Endpoints.remastered
                .getCustomerAlertConditions(installationPoint.id, readingTypeId)
                .then(function (response) {
                if (response === null || response === void 0 ? void 0 : response.data) {
                    setCustomerLevels(_get(response.data, [installationPoint.id, readingTypeId]));
                }
            });
        }
    }, [installationPoint.id, readingTypeId]);
    var canAddMoreAlertLevels = Object.entries(alertConditions).length === 1;
    var _l = useState(false), shouldRefreshChart = _l[0], setShouldRefreshChart = _l[1];
    var addAlertLevel = function () {
        setAlertConditions(function (alertConditions) { return (__assign(__assign({}, alertConditions), { '2': structuredClone(DEFAULT_ALERT_CONDITION) })); });
    };
    var changeAlertCondition = function (path, value) {
        var changedAlertConditionsState = structuredClone(alertConditions);
        _set(changedAlertConditionsState, path, value);
        setAlertConditions(changedAlertConditionsState);
    };
    var setChartFeaturesBack = function (_a) {
        var alertConditions = _a.alertConditions, shouldCloseModal = _a.shouldCloseModal;
        var changeAlertConditions = Object.keys(alertConditions).reduce(function (acc, alertConditionId) {
            Object.entries(alertConditions[alertConditionId]).forEach(function (_a) {
                var _b;
                var key = _a[0], values = _a[1];
                acc[key] = __assign(__assign({}, (acc[key] || {})), (_b = {}, _b[alertConditionId] = values, _b));
            });
            return acc;
        }, {});
        if (selectedInstallationPointId === installationPoint.id) {
            setChartFeatures({
                changeAlertConditions: __assign(__assign({}, changeAlertConditions), { readingTypeId: readingTypeId, selectedReadingTypeIds: selectedReadingTypeIds, isVisibleModal: !shouldCloseModal, shouldUpdate: true, storedLevels: changeAlertConditions.levels, currentLevel: changeAlertConditions.levels }),
            });
        }
    };
    var setConfirmData = useConfirmModalActions().setConfirmData;
    var deleteAlertLevel = function (key, condition) {
        var _a, _b;
        var settings = condition.settings;
        var hasConditionIds = ((_a = settings.caution) === null || _a === void 0 ? void 0 : _a.alert_condition_id) && ((_b = settings.warning) === null || _b === void 0 ? void 0 : _b.alert_condition_id);
        if (hasConditionIds && readingTypeId) {
            var handleConfirm = function () { return __awaiter(void 0, void 0, void 0, function () {
                var _a, cautionResponse, warningResponse;
                var _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            setConfirmData({
                                isVisible: false,
                            });
                            setSmallLoader(true);
                            return [4 /*yield*/, Promise.all([(_b = settings.caution) === null || _b === void 0 ? void 0 : _b.alert_condition_id, (_c = settings.warning) === null || _c === void 0 ? void 0 : _c.alert_condition_id].map(function (alertConditionId) {
                                    return Endpoints[selectedSystemType].deleteOnceAlertLevel({
                                        alertConditionId: alertConditionId,
                                        installationPointId: installationPoint.id,
                                        readingTypeId: readingTypeId,
                                    }, {
                                        query: { duplicated: true },
                                    });
                                }))];
                        case 1:
                            _a = _d.sent(), cautionResponse = _a[0], warningResponse = _a[1];
                            if (cautionResponse.success === true && warningResponse.success === true) {
                                toast.success('Alert level successfully removed', {
                                    icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                                });
                                setAlertConditions(function (alertConditions) {
                                    var newAlertConditions = alertConditions;
                                    if (_size(alertConditions) > 1) {
                                        var superficialCopy = __assign({}, alertConditions);
                                        delete superficialCopy[key];
                                        newAlertConditions = superficialCopy;
                                    }
                                    else {
                                        newAlertConditions = {
                                            '1': structuredClone(DEFAULT_ALERT_CONDITION),
                                        };
                                    }
                                    setChartFeaturesBack({
                                        alertConditions: newAlertConditions,
                                        shouldCloseModal: false,
                                    });
                                    return newAlertConditions;
                                });
                            }
                            setSmallLoader(false);
                            return [2 /*return*/];
                    }
                });
            }); };
            setConfirmData({
                isVisible: true,
                textConfirmationModal: 'Are you sure to delete alert level?',
                onConfirm: handleConfirm,
            });
        }
        else {
            setAlertConditions(function (alertConditions) {
                var superficialCopy = __assign({}, alertConditions);
                delete superficialCopy[key];
                return superficialCopy;
            });
        }
    };
    var deleteAlertCondition = function (levelKey, level, alertCondition) {
        var _a;
        var alertConditionId = (_a = alertCondition.settings[level]) === null || _a === void 0 ? void 0 : _a.alert_condition_id;
        if (alertConditionId && readingTypeId) {
            setConfirmData({
                isVisible: true,
                textConfirmationModal: 'Are you sure to delete alert condition?',
                onConfirm: function () {
                    setConfirmData({
                        isVisible: false,
                    });
                    setSmallLoader(true);
                    Endpoints[selectedSystemType]
                        .deleteOnceAlertLevel({
                        alertConditionId: alertConditionId,
                        installationPointId: installationPoint.id,
                        readingTypeId: readingTypeId,
                    })
                        .then(function (_a) {
                        var success = _a.success;
                        if (success) {
                            toast.success('Alert condition successfully removed', {
                                icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                            });
                            var defaultAlertCondition_1 = structuredClone(DEFAULT_ALERT_CONDITION);
                            setAlertConditions(function (alertConditions) {
                                var _a, _b, _c, _d, _e, _f, _g;
                                return (__assign(__assign({}, alertConditions), (_a = {}, _a[levelKey] = __assign(__assign({}, alertConditions[levelKey]), { conditions: __assign(__assign({}, alertConditions[levelKey].conditions), (_b = {}, _b[level] = defaultAlertCondition_1.conditions[level], _b)), levels: __assign(__assign({}, alertConditions[levelKey].levels), (_c = {}, _c[level] = defaultAlertCondition_1.levels[level], _c)), settings: __assign(__assign({}, alertConditions[levelKey].settings), (_d = {}, _d[level] = defaultAlertCondition_1.settings[level], _d)), fftSettings: __assign(__assign({}, alertConditions[levelKey].fftSettings), (_e = {}, _e[level] = defaultAlertCondition_1.fftSettings[level], _e)), isRmsOd: __assign(__assign({}, alertConditions[levelKey].isRmsOd), (_f = {}, _f[level] = defaultAlertCondition_1.isRmsOd[level], _f)), ids: __assign(__assign({}, alertConditions[levelKey].ids), (_g = {}, _g[level] = null, _g)) }), _a)));
                            });
                        }
                    })
                        .finally(function () {
                        setSmallLoader(false);
                    });
                },
            });
        }
    };
    var handleSubmit = function (event) {
        event.preventDefault();
        var action = event.nativeEvent.submitter.dataset.action;
        var shouldCloseModal = action === 'save&close';
        var submitData = function () {
            setSmallLoader(true);
            var requests = [];
            if (readingTypeId) {
                Object.values(alertConditions).forEach(function (alertCondition) {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1;
                    var levels = {
                        caution: {
                            alert_level: 'caution',
                            reading_type_id: readingTypeId,
                            condition: alertCondition.conditions.caution,
                            settings: {
                                is_ai: (_b = (_a = alertCondition.settings.caution) === null || _a === void 0 ? void 0 : _a.is_ai) !== null && _b !== void 0 ? _b : 0,
                                ai_urgency: (_d = (_c = alertCondition.settings.caution) === null || _c === void 0 ? void 0 : _c.ai_urgency) !== null && _d !== void 0 ? _d : 0,
                                is_pre_ai: (_f = (_e = alertCondition.settings.caution) === null || _e === void 0 ? void 0 : _e.is_pre_ai) !== null && _f !== void 0 ? _f : 0,
                                trigger_time: (_h = (_g = alertCondition.settings.caution) === null || _g === void 0 ? void 0 : _g.trigger_time) !== null && _h !== void 0 ? _h : null,
                                ai_description: (_k = (_j = alertCondition.settings.caution) === null || _j === void 0 ? void 0 : _j.ai_description) !== null && _k !== void 0 ? _k : '',
                                trigger_rms_od: (_m = (_l = alertCondition.settings.caution) === null || _l === void 0 ? void 0 : _l.trigger_rms_od) !== null && _m !== void 0 ? _m : null,
                            },
                            value: String(alertCondition.levels.caution),
                            chart_type: readingTypeId,
                        },
                        warning: {
                            alert_level: 'warning',
                            reading_type_id: readingTypeId,
                            condition: alertCondition.conditions.warning,
                            settings: {
                                is_ai: (_p = (_o = alertCondition.settings.warning) === null || _o === void 0 ? void 0 : _o.is_ai) !== null && _p !== void 0 ? _p : 0,
                                ai_urgency: (_r = (_q = alertCondition.settings.warning) === null || _q === void 0 ? void 0 : _q.ai_urgency) !== null && _r !== void 0 ? _r : 0,
                                is_pre_ai: (_t = (_s = alertCondition.settings.warning) === null || _s === void 0 ? void 0 : _s.is_pre_ai) !== null && _t !== void 0 ? _t : 0,
                                trigger_time: (_v = (_u = alertCondition.settings.warning) === null || _u === void 0 ? void 0 : _u.trigger_time) !== null && _v !== void 0 ? _v : null,
                                ai_description: (_x = (_w = alertCondition.settings.warning) === null || _w === void 0 ? void 0 : _w.ai_description) !== null && _x !== void 0 ? _x : '',
                                trigger_rms_od: (_z = (_y = alertCondition.settings.warning) === null || _y === void 0 ? void 0 : _y.trigger_rms_od) !== null && _z !== void 0 ? _z : null,
                            },
                            value: String(alertCondition.levels.warning),
                            chart_type: readingTypeId,
                        },
                    };
                    if (alertCondition.isRmsOd.caution) {
                        levels.caution.fftSettings = alertCondition.fftSettings.caution;
                    }
                    if (alertCondition.isRmsOd.warning) {
                        levels.warning.fftSettings = alertCondition.fftSettings.warning;
                    }
                    if (((_0 = alertCondition.ids) === null || _0 === void 0 ? void 0 : _0.caution) && ((_1 = alertCondition.ids) === null || _1 === void 0 ? void 0 : _1.warning)) {
                        requests.push(Endpoints[selectedSystemType].updateOnceAlertLevel({
                            installationPointId: installationPoint.id,
                            readingTypeId: readingTypeId,
                            levels: {
                                caution: __assign(__assign({}, levels.caution), { id: alertCondition.ids.caution }),
                                warning: __assign(__assign({}, levels.warning), { id: alertCondition.ids.warning }),
                            },
                        }, { query: { duplicated: true } }));
                    }
                    else {
                        requests.push(Endpoints[selectedSystemType].createOnceAlertLevel({
                            installationPointId: installationPoint.id,
                            readingTypeId: readingTypeId,
                            levels: levels,
                        }, { query: { duplicated: true } }));
                    }
                    // Object.keys(alertCondition.conditions).forEach((alertLevel) => {
                    //   const alertLevelPayload: PayloadForCreateOnceAlertLevel = {
                    //     alert_level: alertLevel,
                    //     reading_type_id: readingTypeId,
                    //     condition: alertCondition.conditions[alertLevel],
                    //     settings: {
                    //       is_ai: alertCondition.settings[alertLevel]?.is_ai ?? 0,
                    //       ai_urgency: alertCondition.settings[alertLevel]?.ai_urgency ?? 0,
                    //       is_pre_ai: alertCondition.settings[alertLevel]?.is_pre_ai ?? 0,
                    //       trigger_time: alertCondition.settings[alertLevel]?.trigger_time ?? null,
                    //       ai_description: alertCondition.settings[alertLevel]?.ai_description ?? '',
                    //       trigger_rms_od: alertCondition.settings[alertLevel]?.trigger_rms_od ?? null,
                    //     },
                    //     value: String(alertCondition.levels[alertLevel]),
                    //     chart_type: readingTypeId,
                    //   };
                    //   const alertLevelId = alertCondition.ids?.[alertLevel];
                    //   if (alertCondition.isRmsOd[alertLevel]) {
                    //     alertLevelPayload.fftSettings = alertCondition.fftSettings[alertLevel];
                    //   }
                    //   if (alertLevelId) {
                    //     requests.push(
                    //       Endpoints[selectedSystemType].updateOnceAlertLevel(
                    //         {
                    //           installationPointId: installationPoint.id,
                    //           readingTypeId,
                    //           levels: {
                    //             [alertLevel]: {
                    //               ...alertLevelPayload,
                    //               id: alertLevelId,
                    //             },
                    //           },
                    //         },
                    //         { query: { duplicated: true } },
                    //       ),
                    //     );
                    //   } else {
                    //     requests.push(
                    //       Endpoints[selectedSystemType].createOnceAlertLevel(
                    //         {
                    //           installationPointId: installationPoint.id,
                    //           readingTypeId,
                    //           levels: {
                    //             [alertLevel]: alertLevelPayload,
                    //           },
                    //         },
                    //         { query: { duplicated: true } },
                    //       ),
                    //     );
                    //   }
                    // });
                });
            }
            // if (forOverlaid) {
            //   data.overlayInstallationPoints = overlayInstallationPoints.map(
            //     (overlayInstallationPoint: IInstallationPoint) => overlayInstallationPoint.id,
            //   );
            // }
            // setForAllEquipment: +forAllSensors,
            // setForAllComponents: +forOverlaid,
            // selected_facility_metric: METRIC_KEY[measure],
            Promise.all(requests)
                .then(function (responses) {
                var allSucceed = responses.every(function (_a) {
                    var success = _a.success;
                    return success;
                });
                if (allSucceed) {
                    toast.success('Successfully saved', {
                        icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                    });
                    setChartFeaturesBack({ alertConditions: alertConditions, shouldCloseModal: shouldCloseModal });
                }
                {
                    responses
                        .map(function (_a) {
                        var errors = _a.errors;
                        return errors;
                    })
                        .flat(1)
                        .forEach(function (_a) {
                        var value = _a.value;
                        toast.error(value || 'Something went wrong', {
                            icon: _jsx(FaTimes, { style: { fill: '#af2929' } }),
                        });
                    });
                }
            })
                .finally(function () {
                setSmallLoader(false);
            });
        };
        var isAutoPreAi = Object.values(alertConditions).some(function (_a) {
            var _b, _c;
            var settings = _a.settings;
            return ((_b = settings.caution) === null || _b === void 0 ? void 0 : _b.is_pre_ai) || ((_c = settings.warning) === null || _c === void 0 ? void 0 : _c.is_pre_ai);
        });
        var showConfirmation = selectedReadingTypeIds.length > 1 || hasAutoAi || isAutoPreAi;
        if (showConfirmation) {
            var textConfirmation = '';
            if (comments.length > 0) {
                textConfirmation +=
                    'These alarm levels were set upon request, are you sure you want to confirm the action? </br>';
            }
            if (selectedReadingTypeIds.length > 1) {
                var axesLabel = selectedReadingTypeIds
                    .map(function (selectedReadingTypeId) { var _a; return (_a = READING_TYPES[selectedReadingTypeId]) === null || _a === void 0 ? void 0 : _a.axisShort; })
                    .join(', ');
                textConfirmation += "Are you sure you want to set the same levels for the ".concat(axesLabel, " axes? </br>");
            }
            if (hasAutoAi || isAutoPreAi) {
                textConfirmation += "This reading type has settings for creation ".concat(hasAutoAi ? 'Auto AI' : '', " ").concat(hasAutoAi && isAutoPreAi ? ' and ' : '', " ").concat(isAutoPreAi ? 'Auto Pre-AI' : '', ", are you sure you want to change the alarm levels?");
            }
            return setConfirmData({
                isVisible: true,
                textConfirmationModal: textConfirmation,
                onConfirm: function () {
                    setConfirmData({
                        isVisible: false,
                    });
                    submitData();
                },
            });
        }
        submitData();
    };
    return (_jsxs(_Fragment, { children: [_jsx(If, { condition: triggerEachReading || comments.length, children: _jsxs(Row, { className: "g-0 mb-2", children: [_jsx(If, { condition: triggerEachReading, children: _jsxs(Col, { children: [_jsx(Badge, { className: "badge-circle", color: "danger", children: _jsx(FaInfo, {}) }), _jsx("span", { className: "fw-bold align-bottom ms-2", children: "This option will be trigger each reading for check alarm" })] }) }), _jsx(If, { condition: comments.length, children: _jsxs(Col, { children: [_jsx(Badge, { className: "badge-circle", color: "warning", children: _jsx(FaExclamation, {}) }), _jsx("span", { className: "fw-bold align-bottom ms-2", children: "This equipment has an active alert notes for alert levels" })] }) })] }) }), _jsx(If, { condition: hasAutoAi, children: _jsx(LossPacketsAlert, { lossPacketsPercent: +packetLossPercent }) }), _jsxs(Row, { children: [_jsx(Col, { md: 6, children: _jsx(Chart, { chartIdentifiers: chartIdentifiers, selectedReadingTypeIds: selectedReadingTypeIds, setSelectedReadingTypeIds: onSelectReadingType, alertConditionsState: alertConditions, shouldRefresh: shouldRefreshChart, selectedInstallationPointId: selectedInstallationPointId }) }), _jsxs(Col, { md: 6, children: [_jsxs("form", { id: "alertLevelForm", onSubmit: handleSubmit, children: [_jsx("div", { className: "alert-levels", "data-has-more": !canAddMoreAlertLevels, children: Object.entries(alertConditions).map(function (_a, _, _b) {
                                            var _c, _d, _e, _f;
                                            var key = _a[0], condition = _a[1];
                                            var alertConditionsLength = _b.length;
                                            var settings = condition.settings;
                                            var hasConditionIds = ((_c = settings.caution) === null || _c === void 0 ? void 0 : _c.alert_condition_id) && ((_d = settings.warning) === null || _d === void 0 ? void 0 : _d.alert_condition_id);
                                            return (_jsxs("section", { className: "alert-levels__section", children: [_jsx(AlertLevelBlock, { levelKey: key, alertConditionsState: condition, level: "caution", customerLevel: (_e = customerLevels === null || customerLevels === void 0 ? void 0 : customerLevels.caution) !== null && _e !== void 0 ? _e : null, setIsVisibleCustomerLevels: setIsVisibleCustomerLevels, isVisibleCustomerLevels: isVisibleCustomerLevels, onChange: changeAlertCondition, onDeleteAlertCondition: deleteAlertCondition }), _jsx(AlertLevelBlock, { levelKey: key, alertConditionsState: condition, level: "warning", customerLevel: (_f = customerLevels === null || customerLevels === void 0 ? void 0 : customerLevels.warning) !== null && _f !== void 0 ? _f : null, setIsVisibleCustomerLevels: setIsVisibleCustomerLevels, isVisibleCustomerLevels: isVisibleCustomerLevels, onChange: changeAlertCondition, onDeleteAlertCondition: deleteAlertCondition }), _jsx(If, { condition: alertConditionsLength > 1 || hasConditionIds, children: _jsxs(Button, { className: "btn-delete-alert-level", color: "danger", onClick: function () { return deleteAlertLevel(key, condition); }, children: [_jsx("span", { children: "Delete level" }), _jsx(FaTrashAlt, {})] }) })] }, key));
                                        }) }), _jsx(If, { condition: canAddMoreAlertLevels, children: _jsx(Button, { className: "mt-4", onClick: addAlertLevel, children: "Add new alert level" }) })] }), _jsxs(If, { condition: readingTypeId !== READING_TYPE_TACHOMETER, children: [_jsx(Row, { className: "g-1 ms-4 mt-2", children: _jsx(Col, { md: 12, children: _jsxs(Label, { check: true, children: [_jsx(Input, { type: "checkbox", checked: forAllSensors, onChange: function () { return setForAllSensors(!forAllSensors); } }), _jsx("span", { className: "fw-bold", children: "Set Alarm for all components on equipment" })] }) }) }), _jsx(If, { condition: Object.keys(overlayInstallationPoints).length !== 0, children: _jsx(Row, { className: "g-1 ms-4 mt-2", children: _jsx(Col, { md: 12, children: _jsxs(Label, { check: true, children: [_jsx(Input, { type: "checkbox", checked: forOverlaid, onChange: function () { return setForOverlaid(!forOverlaid); } }), _jsx("span", { className: "fw-bold", children: "Set Alarm for all overlaid components" })] }) }) }) })] })] })] })] }));
};
