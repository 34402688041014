var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { each as _each, first as _first, get as _get, size as _size, sortBy as _sortBy } from 'lodash';
import { useMemo } from 'react';
import { FaUsersCog } from 'react-icons/fa';
import { FaInfo } from 'react-icons/fa6';
import { Badge, Button, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, UncontrolledTooltip, } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import { ACTION_LIST, READING_TYPES, READING_TYPE_TEMPERATURE } from '../../../../../../constants';
import DefaultTooltip from '../../../../../../shared/components/DefaultTooltip';
import { If } from '../../../../../../shared/components/If';
import InputRange from '../../../../../../shared/components/InputRange';
import { isDistancePinSensor, isPinSensor } from '../../../../../../shared/features/charts/shared';
import { getUoms } from '../../../../../../shared/helper/chart';
import User from '../../../../../../shared/helper/user';
import { ChartFeaturesStore } from '../../../../../../shared/store/charts/chartsContent/ChartFeaturesStore';
import { InstallationPointStore } from '../../../../../../shared/store/charts/chartsContent/InstallationPointStore';
import { selectMeasure, selectPersonalSettingMeasure, } from '../../../../../../shared/store/global/useGlobalStore';
import RichTextEditor from '../../../../../../widgets/TextEditor/TextEditor';
var AlertLevelBlock = function (_a) {
    var _b;
    var level = _a.level, alertConditionsState = _a.alertConditionsState, onChange = _a.onChange, customerLevel = _a.customerLevel, isVisibleCustomerLevels = _a.isVisibleCustomerLevels, setIsVisibleCustomerLevels = _a.setIsVisibleCustomerLevels, levelKey = _a.levelKey;
    var localId = "".concat(levelKey, "_").concat(level);
    var changeAlertConditions = ChartFeaturesStore(function (state) { return ({
        changeAlertConditions: state.changeAlertConditions,
    }); }, shallow).changeAlertConditions, readingTypeId = changeAlertConditions.readingTypeId;
    var _c = InstallationPointStore(function (state) { return ({
        readingTypes: state.readingTypes,
        installationPoint: state.installationPoint,
    }); }, shallow), readingTypes = _c.readingTypes, installationPoint = _c.installationPoint;
    var isImpactVue = _get(readingTypes, [readingTypeId, 'isImpactVue']);
    var measure = selectMeasure();
    var personalSettingMeasure = selectPersonalSettingMeasure();
    var lorValuesImpactVue = _get(installationPoint, 'sensorSpecs.lines_of_resolution') || [];
    if (isImpactVue) {
        var sensorParameters = _get(installationPoint, 'sensorSpecs') || {};
        var fMaxValues = _get(sensorParameters, 'impactvue_fmax', []) || _get(sensorParameters, 'fmax', []) || [];
        var maxAcquisitionTime_1 = _get(sensorParameters, 'maxAcquisitionTime', 60);
        var newLorValues_1 = [];
        _each(lorValuesImpactVue, function (lorValue) {
            newLorValues_1.push(lorValue);
        });
        _each(fMaxValues, function (fMaxValue) {
            var exist = false;
            _each(newLorValues_1, function (lorValue) {
                if (+lorValue > fMaxValue * maxAcquisitionTime_1) {
                    exist = true;
                }
            });
            if (exist) {
                newLorValues_1.push(fMaxValue * maxAcquisitionTime_1);
            }
        });
        lorValuesImpactVue = _sortBy(newLorValues_1);
    }
    var showFft = _size(installationPoint.sensorSpecs) > 0 && !isDistancePinSensor(installationPoint), specs = useMemo(function () {
        return {
            fmax: _get(installationPoint.sensorSpecs, isImpactVue ? 'impactvue_fmax' : 'fmax', []),
            // Need to be changed to lines_of_resolution_on_demand when sensor parameter validator will be fixed
            lor: isImpactVue
                ? lorValuesImpactVue
                : _get(installationPoint.sensorSpecs, 'lines_of_resolution_on_demand', []),
            bandwidth_warning: _get(installationPoint.sensorSpecs, 'bandwidth_warning'),
            maxFMax: _get(installationPoint.sensorSpecs, 'maxFMax'),
            defaultFMax: _get(installationPoint.sensorSpecs, 'defaultFMax'),
            defaultLor: _get(installationPoint.sensorSpecs, 'defaultLor'),
        };
    }, [installationPoint.sensorSpecs]);
    var value = _get(alertConditionsState, ['levels', level], 0), condition = _get(alertConditionsState, ['conditions', level], '>='), setting = _get(alertConditionsState, ['settings', level], {}), fftSetting = _get(alertConditionsState, ['fftSettings', level], {}), isRmsOd = _get(alertConditionsState, ['isRmsOd', level]), fmaxValue = +_get(fftSetting, 'fmax') || specs.defaultFMax || _first(specs.fmax);
    var getDefaultSelectValue = +_get(setting, 'is_ai') === 1 ? 'is_ai' : +_get(setting, 'is_pre_ai') === 1 ? 'is_pre_ai' : '0';
    var user = User.get();
    var fixedCondition = condition;
    if (condition === '<') {
        fixedCondition = '<=';
    }
    if (condition === '>') {
        fixedCondition = '>=';
    }
    var defaultAiMessage = useMemo(function () {
        if (isDistancePinSensor(installationPoint)) {
            if (isPinSensor(installationPoint)) {
                return 'Walking pin detected on the Intralox conveyor. Please inspect immediately';
            }
            return 'A belt sag event was detected on the Intralox conveyor. Please inspect immediately.';
        }
        return "A {condition} ".concat(_get(READING_TYPES, [readingTypeId, 'title'], '').toLowerCase(), " level ({readingValue}) has been detected in a long term. Please inspect for the root cause");
    }, [installationPoint]);
    return (_jsxs("div", { className: "alert-level-block", children: [_jsxs(Row, { className: "g-1", children: [_jsx(Col, { md: 3, children: _jsx(Badge, { color: level === 'caution' ? 'warning' : 'danger', children: level === 'caution' ? 'Caution' : 'Warning' }) }), _jsx(Col, { md: 5, children: _jsx(FormGroup, { children: _jsxs(Input, { id: "alertLevelsConditionSelect", name: "alertLevelsConditionSelect", value: fixedCondition, onChange: function (ev) {
                                    return onChange([levelKey, 'conditions', level], ev.target.value);
                                }, type: "select", children: [_jsx("option", { value: ">=", children: "Above" }), _jsx("option", { value: "<=", children: "Below" })] }) }) }), _jsx(Col, { md: 4, children: _jsx(FormGroup, { children: _jsxs(InputGroup, { children: [_jsx(Input, { type: "number", name: "alertConditionValue", id: "alertConditionValue", step: "0.01", min: "0", onChange: function (ev) {
                                            return onChange([levelKey, 'levels', level], ev.target.value);
                                        }, value: value }), _jsx("div", { className: "input-group-append", children: _jsx(InputGroupText, { children: getUoms(readingTypeId, measure, personalSettingMeasure) }) }), customerLevel && (_jsxs("div", { className: "input-group-append", children: [_jsx(Button, { id: "customer-value-".concat(level), color: "primary", style: { borderRadius: '0 5px 5px 0' }, onClick: function () { return setIsVisibleCustomerLevels(!isVisibleCustomerLevels); }, children: _jsx(FaUsersCog, {}) }), _jsxs(UncontrolledTooltip, { target: "customer-value-".concat(level), children: ["Customer set ", customerLevel, " ", getUoms(readingTypeId, measure, personalSettingMeasure), ".", _jsx("br", {}), "Click to ", isVisibleCustomerLevels ? 'hide' : 'show', " ", "customer's", " levels on chart"] })] }))] }) }) })] }), _jsxs(Row, { className: "g-1", children: [_jsx(Col, { md: 3, children: _jsx("span", { className: "fw-bold", children: "Actions:" }) }), _jsx(Col, { md: 3, children: _jsx(FormGroup, { children: _jsxs(Input, { id: "alertConditionActionSelect", name: "alertConditionActionSelect", value: getDefaultSelectValue, onChange: function (ev) {
                                    return onChange([levelKey, 'settings', level], __assign(__assign({}, setting), { is_ai: +(ev.target.value === 'is_ai'), is_pre_ai: +(ev.target.value === 'is_pre_ai'), urgency: ['is_ai', 'is_pre_ai'].includes(ev.target.value)
                                            ? ACTION_LIST.URGENCY_STANDARD
                                            : null, trigger_time: null, username: user.full_name || '' }));
                                }, type: "select", children: [_jsx("option", { value: "0", children: "No action" }), _jsx("option", { value: "is_ai", children: "Create AI" }), _jsx("option", { value: "is_pre_ai", children: "Create Pre AI" })] }) }) }), _jsx(Col, { md: 3, children: _jsx(FormGroup, { children: _jsxs(Input, { id: "alertConditionAiUrgencySelector", name: "alertConditionAiUrgencySelector", disabled: +_get(setting, 'is_ai') !== 1 && +_get(setting, 'is_pre_ai') !== 1, value: +_get(setting, 'ai_urgency'), onChange: function (ev) {
                                    return onChange([levelKey, 'settings', level, 'ai_urgency'], ev.target.value);
                                }, type: "select", children: [_jsx("option", { value: ACTION_LIST.ACTION_ITEM_URGENCY_STANDARD, children: ACTION_LIST.URGENCY_NAMES[ACTION_LIST.ACTION_ITEM_URGENCY_STANDARD] }), _jsx("option", { value: ACTION_LIST.ACTION_ITEM_URGENCY_HIGH, children: ACTION_LIST.URGENCY_NAMES[ACTION_LIST.ACTION_ITEM_URGENCY_HIGH] }), _jsx("option", { value: ACTION_LIST.ACTION_ITEM_URGENCY_CRITICAL, children: ACTION_LIST.URGENCY_NAMES[ACTION_LIST.ACTION_ITEM_URGENCY_CRITICAL] })] }) }) }), _jsx(Col, { md: 3, children: _jsx(FormGroup, { children: _jsxs(Input, { id: "alertConditionTriggerTimeSelector", name: "alertConditionTriggerTimeSelector", disabled: +_get(setting, 'is_ai') !== 1 && +_get(setting, 'is_pre_ai') !== 1, value: +_get(setting, 'trigger_time'), onChange: function (ev) {
                                    return onChange([levelKey, 'settings', level, 'trigger_time'], ev.target.value);
                                }, type: "select", children: [_jsx("option", { value: "", disabled: true, children: "Trigger time" }), _jsx("option", { value: "", children: "Not set" }), _jsx("option", { value: "30", children: "30 minutes" }), _jsx("option", { value: "60", children: "1 hour" }), _jsx("option", { value: "120", children: "2 hours" }), _jsx("option", { value: "180", children: "3 hours" }), _jsx("option", { value: "360", children: "6 hours" }), _jsx("option", { value: "720", children: "12 hours" }), _jsx("option", { value: "1440", children: "24 hours" }), _jsx("option", { value: "2880", children: "48 hours" }), _jsx("option", { value: "5760", children: "72 hours" })] }) }) })] }), showFft && (_jsxs(Row, { className: "g-1", children: [_jsx(Col, { md: 3, className: "ps-4", children: _jsxs(Label, { check: true, children: [_jsx(Input, { type: "checkbox", checked: +isRmsOd === 1, onChange: function () { return onChange([levelKey, 'isRmsOd', level], +!isRmsOd); } }), _jsx("span", { className: "fw-bold", children: "Request FFT" })] }) }), +isRmsOd === 1 && (_jsxs(_Fragment, { children: [_jsx(Col, { md: 3, children: _jsxs(FormGroup, { children: [_jsx(Label, { for: "alertConditionTriggerTimeSelector", children: "FMax:" }), _size(specs.fmax) > 0 ? (_jsx(Input, { id: "alertConditionTriggerFmaxSelector", name: "alertConditionTriggerFmaxSelector", value: +fmaxValue, onChange: function (ev) {
                                                return onChange([levelKey, 'fftSettings', level, 'fmax'], ev.target.value);
                                            }, type: "select", children: specs.fmax.map(function (value) { return (_jsx("option", { value: value, children: value }, "fmax_select".concat(value))); }) })) : (_jsxs(_Fragment, { children: [_jsx(Input, { type: "number", id: "alertConditionTriggerFmaxSelector", name: "alertConditionTriggerFmaxSelector", value: +fmaxValue, step: 1, min: 1, max: specs.maxFMax, onChange: function (ev) {
                                                        if (ev.target.value <= specs.maxFMax && +ev.target.value >= 1) {
                                                            onChange([levelKey, 'fftSettings', level, 'fmax'], ev.target.value);
                                                        }
                                                    } }), _jsx("div", { children: _jsx(InputRange, { maxValue: +specs.maxFMax, minValue: 1, value: +fmaxValue, onChange: function (value) {
                                                            if (value <= +specs.maxFMax && +value >= 1) {
                                                                onChange([levelKey, 'fftSettings', level, 'fmax'], value);
                                                            }
                                                        } }) })] }))] }) }), _size(specs.lor) > 0 ? (_jsx(Col, { md: 3, children: _jsxs(FormGroup, { children: [_jsx(Label, { for: "alertConditionTriggerTimeSelector", children: "Lines of resolution:" }), _jsx(Input, { id: "alertConditionTriggerLorSelector", name: "alertConditionTriggerLorSelector", value: +_get(fftSetting, 'lor') || specs.defaultLor, onChange: function (ev) {
                                                return onChange([levelKey, 'fftSettings', level, 'lor'], ev.target.value);
                                            }, type: "select", children: specs.lor.map(function (value) { return (_jsx("option", { value: value, children: value }, "lor_select".concat(value))); }) })] }) })) : (_jsx(_Fragment, {})), _jsx(Col, { md: 3, children: _jsxs(FormGroup, { children: [_jsx(Label, { for: "FftTriggerFrequency", children: "FFT Trigger Frequency" }), _jsx("div", { className: "input-group", children: _jsx(Input, { min: 0, max: 1000, type: "number", value: (_b = fftSetting === null || fftSetting === void 0 ? void 0 : fftSetting.limit) !== null && _b !== void 0 ? _b : 5, defaultValue: 5, "data-tooltip-id": "fft-trigger-frequency", onChange: function (ev) {
                                                    return onChange([levelKey, 'fftSettings', level, 'limit'], +ev.target.value);
                                                } }) }), _jsx(DefaultTooltip, { style: { zIndex: 1000, fontWeight: 500 }, place: "bottom", openEvents: { mouseenter: true }, id: "fft-trigger-frequency", children: "The number of possible FFT requests based on triggers in one day" })] }) })] }))] })), showFft &&
                +isRmsOd === 1 &&
                !!(+_get(specs, 'bandwidth_warning') && +fmaxValue > +_get(specs, 'bandwidth_warning')) && (_jsx("div", { className: "text-primary", children: _size(specs.fmax) > 0
                    ? 'The Fmax selected exceeds the flat response bandwidth.'
                    : 'Aliasing may occur if the Fmax is adjusted.' })), readingTypeId === READING_TYPE_TEMPERATURE && (_jsx(Row, { className: "g-1", children: _jsx(Col, { md: 12, className: "ps-4", children: _jsxs(Label, { check: true, children: [_jsx(Input, { type: "checkbox", checked: +_get(setting, 'trigger_rms_od') === 1, onChange: function () {
                                    return onChange([levelKey, 'settings', level, 'trigger_rms_od'], +!_get(setting, 'trigger_rms_od'));
                                } }), _jsx("span", { className: "fw-bold", children: "Request RMS" })] }) }) })), _jsx(If, { condition: +_get(setting, 'is_ai', 0) === 1 || +_get(setting, 'is_pre_ai', 0) === 1, children: _jsx(Row, { className: "g-0 mt-1 mb-2", children: _jsxs(Col, { md: 12, children: [_jsxs("span", { children: [_jsxs("b", { children: ["Action ", "item's", " description for ", level, " level."] }), ' ', "Supported variables for action ", "item's", " description", ' ', _jsx(Badge, { className: "align-top ms-2", style: { minWidth: '10px' }, color: "info", id: "ai_description_variables_".concat(localId), children: _jsx(FaInfo, {}) })] }), _jsx(UncontrolledTooltip, { placement: "right", target: "ai_description_variables_".concat(localId), children: _jsxs("table", { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "Variable" }), _jsx("th", { children: "Explanation" })] }) }), _jsxs("tbody", { children: [_jsxs("tr", { className: "border-bottom", children: [_jsx("td", { children: '{condition}' }), _jsxs("td", { children: ['high', " or ", 'low'] })] }), _jsxs("tr", { children: [_jsx("td", { children: '{readingValue}' }), _jsx("td", { children: "Value of triggered reading" })] })] })] }) }), _jsx("div", { className: "mt-2", children: _jsx(RichTextEditor, { customerId: installationPoint.customer_id, content: _get(setting, 'ai_description') || defaultAiMessage, onChange: function (content) { return onChange([levelKey, 'settings', level, 'ai_description'], content); } }) })] }) }) })] }));
};
export default AlertLevelBlock;
