import { keys as _keys, pickBy as _pickBy } from 'lodash';
import { READING_TYPES } from '../../constants';
export var getAvailableChartIdentifiersByReadingType = function (readingTypeId, withImpactVue) {
    var _a;
    var groupId = (_a = READING_TYPES[readingTypeId]) === null || _a === void 0 ? void 0 : _a.groupId;
    if (!groupId) {
        return [];
    }
    return _keys(_pickBy(READING_TYPES, function (readingType) {
        return readingType.groupId === groupId && (withImpactVue || !readingType.isImpactVue);
    })).map(Number);
};
